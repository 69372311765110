import { formatNumber, Skeleton } from 'react-ui-kit-exante';

import { DEFAULT_POOLING_INTERVAL_DATA_20 } from 'constants/common';
import { useGetAccumMarketValueQuery } from 'store/accumMarketValue';
import { IUseQueryReturn, TAccumulationMarketValue } from 'types';

import { LabelContainer, ValueContainer, Wrapper } from './styled';

export const MarketValue = () => {
  const { data, isLoading, isFetching } = useGetAccumMarketValueQuery<
    IUseQueryReturn<TAccumulationMarketValue>
  >(
    {
      workflow_execution_ids: [],
    },
    {
      pollingInterval: DEFAULT_POOLING_INTERVAL_DATA_20,
    },
  );

  const isInitialLoad = !data && (isLoading || isFetching);

  return (
    <Wrapper>
      <ValueContainer>
        {isInitialLoad ? (
          <Skeleton
            width={140}
            height={20}
            variant="rounded"
            sx={{ marginBottom: '3px' }}
          />
        ) : (
          formatNumber(data?.market_value || 0)
        )}
      </ValueContainer>
      <LabelContainer>Market Value, EUR</LabelContainer>
    </Wrapper>
  );
};
