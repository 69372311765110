import { Switch as SwitchUI } from '@mui/material';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { FC } from 'react';

import { DefaultThemeProvider, styled } from 'theme';

import { blockNonNativeProps } from '../../../helpers';

import { IUISwitchProps } from './types';

interface ILabelProps extends FormControlLabelProps {
  hiddenLabel: boolean;
  disabled: boolean;
  warning?: boolean;
}

const Label = styled(FormControlLabel, {
  shouldForwardProp: blockNonNativeProps(['hiddenLabel', 'warning']),
})<ILabelProps>(
  ({ disabled, hiddenLabel, theme, labelPlacement, warning }) => ({
    opacity: disabled ? 0.4 : 1,
    margin: 0,
    '& .MuiFormControlLabel-label': {
      marginLeft: hiddenLabel ? 0 : 8,
      marginRight: labelPlacement === 'start' ? 8 : 0,
      fontSize: '15px',
      lineHeight: '16px',
      fontFamily: theme?.font.main,
      color: warning ? theme?.color.input.warning : theme?.color.typo.promo,
      fontWeight: 400,
    },
  }),
) as React.ComponentType<ILabelProps>;

const Control = styled(
  (props: any) => (
    <SwitchUI
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ),
  { shouldForwardProp: blockNonNativeProps('warning') },
)(({ theme, warning }) => ({
  width: 40,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        background: warning
          ? theme.color.input.warning
          : theme.color.controls.gr,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.9,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: warning ? theme.color.input.warning : theme.color.icon.action,
      border: '6px solid #fff',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.8,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    backgroundColor: warning
      ? theme.color.input.transparentWarning
      : theme.color.bg.basic,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));

export const Switch: FC<IUISwitchProps> = ({
  checked,
  controlProps,
  disabled,
  onChange,
  label = '',
  ...props
}) => {
  const hasLabel = Boolean(label);

  return (
    <DefaultThemeProvider>
      <Label
        disabled={disabled || false}
        hiddenLabel={!hasLabel}
        control={
          <Control
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            warning={props.warning}
            {...controlProps}
          />
        }
        label={label}
        {...props}
      />
    </DefaultThemeProvider>
  );
};
